// Required
@import "./custom/site/site";
@import "./node_modules/bootstrap/scss/functions";
@import "./custom/variables/variables";
@import "./custom/variables/variables-dark";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/variables-dark";
@import "./node_modules/bootstrap/scss/maps";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/utilities";
@import "./custom/utilities/utilities";
@import "./node_modules/bootstrap/scss/bootstrap.scss";

//Custom css variables for Assan Theme
@import "./custom/utilities/root";


//Theme core style import
@import "./custom/components/timeline";
@import "./custom/mixins/transition";
@import "./custom/utilities/typography";
@import "./custom/utilities/animations";
@import "./custom/components/components";
@import "./custom/utilities/dark-mode";

.swiper-pagination .swiper-pagination-bullet {
    width: 2rem;
    height: 2rem;
}

.navbar-brand {
    display: contents;
    min-width: 138px!important;
}

.navbar-brand-sticky, .navbar-brand-transparent {
    background: #fff;
    padding: 4px;
    border-radius: 4px;
    min-height: 44px;
    width: auto!important;
    max-height: 4px;
}

.section-image-cards .img-cards {
    max-height: 400px;
}

.swiper-autoheight .swiper-wrapper {
    height: auto !important;
}

@media screen and (max-width: 768px) {
    .item-image-row {
        justify-content: flex-start!important;
    }
    .item-image-col {
        justify-content: flex-start;
        display: flex;
    }
    .section_testimonial .swiper-slide img {
        width: 25%!important;
    }
}
.swiper-pagination .swiper-pagination-bullet {
    border-radius: 50%;
    background: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important;
}
@media screen and (min-width: 1400px) {
    .swiper-pagination .swiper-pagination-bullet {
        border-radius: 50%;
    }
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    margin: 0 8px;
    transform: scale(0.5);
    --bs-text-opacity: 1;
    background: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important;
}

@media screen and (max-width: 599px) {
    [data-bs-theme="dark"] {
        .portfolio .swiper-slide .align-items-end {
            background-color: #080713;
            // color: black;
        }
    }

    [data-bs-theme="light"], [data-bs-theme="auto"] {
        .portfolio .swiper-slide .align-items-end {
            background-color: #fff;
            color: black;
        }
    }
}

// Coral Hive
.swiper-hero, .swiper-slide {
    height: auto;
}

@media screen and (min-width: 1440px) {
    .swiper-hero {
        height: calc(100vh - 66px);
    }
}
