@charset "UTF-8";

.mb-6 {
    margin-bottom: 2.5rem!important;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.text-center {
    text-align: center!important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
}

.d-inline-block {
    display: inline-block!important;
}

.mb-5 {
    margin-bottom: 2rem!important
}

.display-3 {
    font-size: calc(1.5rem + 3vw);
    font-weight: 600;
    line-height: 1.2
}

.h2,h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width: 1200px) {
    .display-3 {
        font-size:3.75rem
    }

    .h2,h2 {
        font-size: 2rem
    }
}

.flex-center {
    display: inline-flex!important
}
.hover-shadow,[class*=hover-shadow-],[class~=hover-shadow-] {
    transition: all .25s ease-in-out
}

.typed-cursor {
    font-weight: 300;
    font-size: 75%;
    display: inline-block;
    line-height: inherit;
    vertical-align: middle
}

.typed-cursor.typed-cursor--blink {
    animation: blink .6s both infinite
}

.theme-icon-active i {
    font-family: boxicons!important
}

html[data-bs-theme=light] .theme-icon-active i::after {
    content: "\eebe"
}

html[data-bs-theme=dark] .theme-icon-active i::after {
    content: "\ee4e"
}

html[data-bs-theme=auto] .theme-icon-active i::after {
    content: "\ed56"
}

.swiper-pagination .swiper-pagination-bullet {
    width: 2rem;
    height: 2rem
}

.section-image-cards .img-cards {
    max-height: 400px
}

@media screen and (min-width: 1400px) {
    .swiper-pagination .swiper-pagination-bullet {
        width:3rem;
        height: 3rem;
        border-radius: 50%
    }
}

.link-hover-underline {
    position: relative;
    display: inline-block
}

.link-hover-underline::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    top: 100%;
    left: .05em;
    right: .05em;
    background: currentColor;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .6s cubic-bezier(1,0,0,1)
}