//Navs


.nav {
    &.flex-column {
        .nav-link {
            padding-left: 0;
        }
    }
}
.text-white,.text-light{
    --#{$prefix}body-color:#{$white};
    --#{$prefix}body-color-rgb: 255,255,255;
    .nav{
        --#{$prefix}text-opacity: .75;
        --#{$prefix}nav-link-color: rgba(var(--#{$prefix}body-color-rgb),var(--#{$prefix}text-opacity));
        --#{$prefix}nav-link-hover-color: #{$white};
        --#{$prefix}nav-link-active-color: #{$white};
        --#{$prefix}nav-link-disabled-color: #{$text-muted};
      .nav-link.active{
        color: var(--#{$prefix}nav-link-active-color);
      }
    }
}
.nav.nav-filter {
    .nav-link {
        position: relative;
        padding: 0;

        &.active {
            text-decoration: line-through;
        }
    }

    &:not(.flex-column) {
        .nav-item:not(:last-child) {
            margin-right: $spacer;
        }
    }
}

.nav-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px; // to prevent active links border bottom from hiding
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }

//Nav vertical outline
.nav-tabs-vertical{
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
}
.nav.nav-pills.nav-pills-white{
    .nav-link.active{
        background-color: $component-active-bg;
        color: $component-active-color;
    }
}

//Underline tabs
.nav-tabs{
    border:0px;
    .nav-link{
        padding-left: 0px;
        padding-right: 0px;
        &:not(:first-child){
            margin-left: 1.75rem;
        }
    }
    .nav-link{
        border: 0px;
        background-color: transparent;
        
        &.active{
            background-color: transparent;
        }
    }
}

.nav-link.active{
    color: var(--#{$prefix}link-hover-color);
}